<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <img :src="detailData.pictureUrl" class="top-img"/>
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.activityName}}</label>
          </div>
          <div class="condition">
            <label>报名时间： {{detailData.registrationTimeStart}} 至 {{detailData.registrationTimeEnd}}</label>
            <label>举办时间： {{detailData.hostTimeStart}} 至 {{detailData.hostTimeEnd}}</label>
            <label>举办地点： {{detailData.hostVenue}}</label>
          </div>
        </div>
      </div>
      <div class="desc">
        <div class="desc-item">
          <label class="info-text">主办单位</label><label class="info-text">{{detailData.organizer}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">联系人</label><label class="info-text">{{detailData.contactName}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">联系电话</label><label class="info-text">{{detailData.contactPhone}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">电子邮箱</label><label class="info-text">{{detailData.contactEmail}}</label>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">组织单位</div>
          <div class="content-text">{{detailData.organizer}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">活动内容</div>
          <div class="content-text">{{detailData.activityContent}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'activeDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      activityTypeDic:{1:"培训活动",2:"挑战赛活动",3:"其他活动"},
      id:null,
      detailData:{}
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.technologyFinance.activityDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img{
    height:67px;
    width:117px;
  }
  .top-info{
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name{
      display: flex;
      align-items: center;
      flex-direction: row;
      .type{
        font-weight: 700;
      }
    }
    .condition{
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item{
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text{
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: 20px 80px;
  .content-item{
    margin: 40px 0;
    .content-title{
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text{
      margin-top: 10px;
      font-size: 14px;
    }
  }

}

</style>
